import { Controller, useFormContext } from 'react-hook-form';

// import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import useLocales from '#/hooks/useLocales';

// import { countries } from 'src/assets/data';

// import Iconify from 'src/components/iconify';

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  type?: 'country' | string;
  helperText?: React.ReactNode;
}

export default function RHFAutocompleteTwo<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  label,
  type,
  helperText,
  placeholder,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue } = useFormContext();
  const { translate } = useLocales();

  // const { multiple } = other;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...field}
            id={`autocomplete-${name}`}
            onChange={(event, newValue) =>
              setValue(name, newValue, { shouldValidate: true })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                error={!!error}
                helperText={
                  error ? String(translate(error?.message)) : helperText
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: (theme) => theme.palette.divider,
                    },
                  },
                }}
              />
            )}
            {...other}
          />
        );
      }}
    />
  );
}
